exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-aws-js": () => import("./../../../src/pages/aws.js" /* webpackChunkName: "component---src-pages-aws-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gatsby-js": () => import("./../../../src/pages/gatsby.js" /* webpackChunkName: "component---src-pages-gatsby-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-java-js": () => import("./../../../src/pages/java.js" /* webpackChunkName: "component---src-pages-java-js" */),
  "component---src-pages-javascript-js": () => import("./../../../src/pages/javascript.js" /* webpackChunkName: "component---src-pages-javascript-js" */),
  "component---src-pages-other-js": () => import("./../../../src/pages/other.js" /* webpackChunkName: "component---src-pages-other-js" */),
  "component---src-pages-plypolicy-js": () => import("./../../../src/pages/plypolicy.js" /* webpackChunkName: "component---src-pages-plypolicy-js" */),
  "component---src-pages-python-js": () => import("./../../../src/pages/python.js" /* webpackChunkName: "component---src-pages-python-js" */),
  "component---src-pages-react-js": () => import("./../../../src/pages/react.js" /* webpackChunkName: "component---src-pages-react-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-blog-detail-js": () => import("./../../../src/templates/blogDetail.js" /* webpackChunkName: "component---src-templates-blog-detail-js" */)
}

